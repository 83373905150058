import React from "react";
import {Link} from 'react-router-dom';

function Footer() {
	return (
		<>

			<div className="cta">
				<div className="container">
					<div className="row">
						<div className="col">
							<div
								className="cta_content d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-start">
								<div className="cta_text text-center">Vem pro Mambeba! <span>Vem ser feliz!</span></div>
								<div className="cta_button button ml-md-auto buttona1"><a href="https://app.mgdata.com.br/tickets/?fbclid=PAZXh0bgNhZW0CMTEAAabiLUrCWdisSYwIbFyY28Ba5Q4BEIXEOnjohUByr0tsWVhtw6FP5eisfj0_aem_UgI3hDfSj-0preZLnPAx3Q">Faça sua reserva</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>



			<footer className="footer">
				<div className="footer_container">
					<div className="container">
						<div className="row">
							<div className="col-lg-5 order-lg-1 order-3">
								<div
									className="footer_image d-flex flex-column align-items-lg-start align-items-center justify-content-end">
									<div><img src="images/footer.png" alt="" /></div>
								</div>
							</div>
							<div className="col-lg-3 order-lg-2 order-1">
								<div className="footer_contact_info">
									<div className="footer_logo"><a href="#"><img src="images/logomam.png" alt="" /></a></div>
									<div className="footer_contact_list">
										<ul>
											<li className="d-flex flex-row align-items-start justify-content-start">
												<div>Endereço</div>
												<div>Rodovia, MG-431, 38, Itaúna - MG</div>
											</li>
											<li className="d-flex flex-row align-items-start justify-content-start">
												<div>Telefone</div>
												<div>+37 98842 3461</div>
											</li>
											<li className="d-flex flex-row align-items-start justify-content-start">
												<div>E-mail</div>
												<div>contato@mambeba.com.br</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 order-lg-3 order-2">
								<div className="newsletter">
									<div className="newsletter_title">Inscreva-se e Saiba das Novidades</div>
									<div className="newsletter_form_container">
										<form action="contact.php" className="newsletter_form" method="post" id="contactForm" novalidate="novalidate">
											<div className="d-flex flex-row align-items-start justify-content-start">
												<input type="email" name="email" className="newsletter_input"
													placeholder="Seu Email" required="required" />
												<button className="newsletter_button">Enviar</button>
											</div>
										</form>
										<div className="newsletter_text">
											<p>Saiba de todos os eventos do Mambeba</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer_bar">
					<div className="container">
						<div className="row">
							<div className="col">
								<div
									className="footer_bar_content d-flex flex-md-row flex-column align-items-md-center align-items-start justify-content-start">
									<div className="cr order-md-1 order-2">

										Copyright &copy;
										<script>document.write(new Date().getFullYear());</script> All rights reserved <i
											className="fa fa-heart-o" aria-hidden="true"></i> <a href="https://www.instagram.com/dot.midias/"
												target="_blank">DOT.Midias</a>

									</div>
									<nav className="footer_nav ml-md-auto order-md-2 order-1">
										<ul className="d-flex flex-row align-items-start justify-content-start flex-wrap">
										<li><a href='https://www.mambeba.com.br'>Home</a></li>
											<li><Link to='/sobre'>Sobre</Link></li>
											<li><a href="https://app.mgdata.com.br/tickets/?fbclid=PAZXh0bgNhZW0CMTEAAabiLUrCWdisSYwIbFyY28Ba5Q4BEIXEOnjohUByr0tsWVhtw6FP5eisfj0_aem_UgI3hDfSj-0preZLnPAx3Q">Entradas</a></li>

											<li><a href="contact.html">Contato</a></li>
										</ul>
									</nav>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}
export default Footer;